import React, { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { motion, AnimatePresence } from 'framer-motion';
import { useClickOutsideRef } from '../../logic/hooks/useClickOutsideRef';

import Button from '../buttons/Button';

const variants = {
  initial: {
    opacity: 0,
  },
  isOpen: {
    opacity: 1,
  },
  exit: { opacity: 0 },
};

export default function Picker(props) {
  // handling clicks outside of picker
  const wrapperRef = useRef(null);
  useClickOutsideRef(wrapperRef, props.toggleIsOpen);
  return (
    <div className="pickerContainer">
      <Button
        name={props.variable}
        buttonClick={props.toggleIsOpen}
        className="plain buttonPrev"
        width={props.buttonWidth}
      />
      <AnimatePresence exitBeforeEnter>
        {props.isOpen && (
          <motion.ul
            className="pickerOverlay"
            variants={variants}
            initial={'initial'}
            animate={'isOpen'}
            exit={'exit'}
            ref={wrapperRef}
            style={{ width: props.overlayWidth }}
          >
            <Scrollbars thumbSize={100}>
              {props.content.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      props.onClick(item);
                      props.toggleIsOpen();
                    }}
                  >
                    {item}
                  </li>
                );
              })}
            </Scrollbars>
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
}
