import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { Paths } from "./routes/Paths";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Meetings from "./pages/Meetings";

export default function App() {
  const [token, setToken] = useState(false);

  function guestClick() {
    setToken(true);
  }

  if (!token) {
    return <Login setToken={setToken} getToken={guestClick} />;
  }

  return (
    <Router>
      <Routes>
        <Route exact path="/"></Route>
        <Route exact path={Paths.Dashboard.path} component={Dashboard} />
        <Route exact path={Paths.Meetings.path} component={Meetings} />
        <Route exact path={Paths.Login.path} component={Login} />
      </Routes>
    </Router>
  );
}
